h3 {
    font-size: clamp(1.25rem, 3vw, 1.5rem);
}
p {
    font-size: clamp(1.33rem, 1vw, 1.66rem);
    margin-left: clamp(1rem, 1.5vw, 1.5rem);
}
div > div {
    margin-left: clamp(6rem, 3vw, 11rem);
    margin-right: clamp(6rem, 3vw, 11rem);
    margin-top: clamp(3rem, 3vw, 6rem);
}
div#about > div.header,
div#services > div.header {
    background: var(--onyx);
    color: var(--white);
    padding: .75em;
    margin: auto;
}
div.header > .inner {
    width: clamp(400px, 80vw, 1200px);
    margin: auto;
}
div#about > div.header {
    border-top: solid 1px var(--canada-red);
}
div#about > p {
    width: clamp(400px, 80vw, 1200px);
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
div#services > ul {
    display: flex;
    flex-flow: column;
    row-gap: .8rem;

    padding: 0;
    width: clamp(400px, 80vw, 1200px);
    margin: auto;
}
li.container {
    display: flex;
    justify-content: space-between;
    padding: 1.5em;
    background: linear-gradient(135deg, var(--lightest-grey), var(--lighter-grey));
}
li.container > div {
    width: 100%;
}
li.container > div.left > img {
    max-width: 100% ;
    max-height: 100px;
}
div#bottom-contact-div {
    text-align: center;
    margin: 2rem;
}
div#bottom-contact-div > button {
    font-size: 130%;
}