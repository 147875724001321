header {
    align-items: center;
    background: var(--white);
    color: var(--charcoal);
    display: flex;
    height: calc(40px + 5vh);
    justify-content: space-between;
    top: 0;
    width: 100vw;
    max-width: 100vw;
    z-index: 10;
}

header img#header-logo {
    height: calc(40px + 5vh);
    margin-left: clamp(0px, 2rem, 2vw);
    margin-right: auto;
}

header nav {
    maring: auto;
    width: clamp(100px, 100%, calc(40px + 95vw));
    white-space: nowrap;
}

header nav li {
    list-style: none;
    display: inline-block;
    padding: 0 clamp(5px, 20px, 20px);
    float: right;
    font-size: clamp(0.5rem, 1rem, 2rem);
}
header nav a {
    text-decoration: none;
    color: var(--charcoal);
    transition: all 0.3s ease 0s;
}
header nav a:hover {
    color: var(--canada-red);
}

/*nav > ul > li {*/
/*    cursor: pointer;*/
/*    display: flex;*/
    /*flex: 0 0 1;*/
    /*flex-shrink: 0;*/
    /*font-family: 'latolight';*/
    /*font-size: calc(20px + 1vw);*/
    /*padding: .25rem;*/
    /*display: block;*/
    /*transition-duration: 0.5s;*/
/*}*/
/*li:hover > ul > li {*/
/*    display: block;*/
/*}*/
/*li > ul > li {*/
/*    position: absolute;*/
/*    transition: all 0.5s ease;*/
/*    margin-top: 1rem;*/
/*    right: 0;*/
/*    display: none;*/
/*}*/
