#feature {
    --feature-height: 100%;
    background: radial-gradient(
        var(--onyx),
        var(--charcoal));
    height: var(--feature-height);
    overflow: hidden;
    position: relative;
}
#feature > #banner {
    width: clamp(60vw, 100%, 100%);
}
#feature > #leaf {
    background-image: url('../../public/translucent_leaf.png');
    background-size: contain;
    background-repeat: no-repeat;
    bottom: 0;
    right: 0;
    position: absolute;
    width: var(--feature-height);
    height: var(--feature-height);
}
#feature > #headline {
    position: absolute;
    color: var(--white);
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    bottom: calc(10px + 5%);
    left: calc(5px + 2%);
    margin-left: 0;
}
#feature > #headline > #title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.75rem;
    font-weight: 900;
    margin-left: 0;
}
#feature > #headline > #tagline {
    font-family: century-gothic, sans-serif;
    font-size: 1.25rem;
    padding-top: .25rem;
    border-top: 3px solid var(--canada-red);
    margin-left: 0;
    margin-top: 0;
}
#feature > div:first-child {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
}
#feature > div > h3 {
    animation: phase-in infinite;
    position: absolute;
    padding: 0;
    margin: 0;
    opacity: 0;
    color: var(--white);
    white-space: nowrap;
}
@keyframes phase-in {
    0% {
        opacity: 0;
        font-size: 10rem;
    }
    10% {
        opacity: 1;
        font-size: 3rem;
    }
    21%, 100% {
        opacity: 0;
    }
}
