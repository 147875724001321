:root {
    /* Neutral */
    --white: hsl(0, 0%, 100%);
    --charcoal: hsl(0, 0%, 13%);
    --onyx: hsl(200, 5%, 24%);
    --lightest-grey: #eeeeee;
    --lighter-grey: #dddddd;

    /* Accents */
    --uranium-blue: hsl(201, 100%, 82%);
    --blue-jeans: hsl(207, 100%, 64%);
    --sunglow: hsl(44, 98%, 62%);
    --canada-red: hsl(359, 86%, 33%)
}
:global(html) {
    scroll-padding-top: 60px;
    height: 100vh;
    font-family: "Source Sans P", monospace;
}
:global(body) {
    background: var(--white);
    padding: 0;
}
textarea,
input,
select {
    font-size: inherit;
    font-weight: inherit;
    text-decoration: none;
    font-family: inherit;
    color: var(--charcoal);
    border-radius: 5px;
}
input.error,
textarea.error {
    border: none;
    outline: 2px solid red;
    border-radius: 5px;
}
button {
    padding: .33em;
    background: var(--canada-red);
    color: var(--white);
    border: solid 0.2em transparent;
    border-radius: .25em;
    font-size: 90%;
}
button:hover {
    cursor: pointer;
    filter: brightness(0.9);
    transition: all 0.3s ease 0s;
}

div.modal {
    display: flex;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1010;

    min-width: 80%;
    max-width: 100%;
    margin: auto;
}
div.modal.closed {
    display: none;
}
div.modal-inner {
    background: var(--white);
    width: 100%;

    margin: auto;
    padding: 2rem;
    border-radius: .5em;
}
div.modal-inner > h3 {
    text-align: center;
    margin-top: 1rem;

    font-weight: normal;
    font-size: 2rem;
}
div.modal-overlay {
    display: flex;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;

    background: var(--onyx);
    opacity: 0.95;
}
div.modal-overlay.closed {
    display: none;
}
button.close-modal {
    float: right;
    background: transparent;
    color: black;
    font-size: 100%;

    border: solid 0.2em transparent;
    border-radius: .25em;
    padding: .33em;
}
button.close-modal::after{
    content: '✕';
}
button.close-modal:hover {
    cursor: pointer;
    transition: all 0.3s ease 0s;
    font-weight: bolder;
}