form#contact-form {
    display: flex;
    flex-flow: column;
    row-gap: 1em;
}
form#contact-form > input {
    font-size: 120%;
}
form#contact-form > textarea {
    font-size: 120%;
    min-height: 8rem;
}
form#contact-form > button {
    max-width: 100px;
}
form > p {
    display: none;
}
form > p.error {
    display: inline-block;
    font-size: 80%;
    padding: 0;
    margin-top: auto;
    margin-bottom: auto;
    color: red;
}